const CONSTANTS = {
	AD_CONTENT: {
		podcast: {
			campaign: {
				defaultStanCampaign: {
					pageContent: {
						heroHeader: 'Your All·in·One Podcast Store',
						heroSubHeader: 'The easiest way for podcasters to grow, monetize, and engage their audience — all from your link-in-bio.',
						heroImage: '/images/home/ad-campaign/hero-podcast.png',
						replaceHero: true,
					},
				},
			},
		},
		'healthwellness-gtm': {
			campaign: {
				'all-in-one': {
					pageContent: {
						heroHeader: 'Build your fitness empire with Stan',
						heroSubHeader:
							'The easiest platform for health and wellness creators to grow, monetize, and connect with your audience. Offer digital workout plans, schedule coaching sessions, and build a thriving fitness community -  all from your link-in-bio.',
						heroImage: '/images/home/ad-campaign/hero-healthwellness-all-in-one.png',
						replaceHero: true,
					},
				},
			},
		},
	},
	FAKE_PROFILES: {
		justinwelsh: {
			full_name: 'Justin Welsh',
			pageContent: {
				heroHeader: 'Your All-in-One Solopreneur Store',
				heroSubHeader: 'The Saturday Solopreneur community gets their first month free when joining Stan. Start your business today.',
				referralComment:
					// eslint-disable-next-line
					"“I've tested a lot of online platforms. Stan blows me away. It's by far the easiest, fastest, and most affordable choice for anyone starting out as a solopreneur.”",
				headshot: '/images/home/v6/justin-welsh-headshot.png',
				heroImage: '/images/home/v6/justin-welsh-phone-preview.png',
				// eslint-disable-next-line
				stanXcreatorHeader: "Exclusive ONLY to Justin's Community!",
				stanXcreatorSubheader:
					// eslint-disable-next-line
					"Justin is the only person who has a free 30 days trial when joining Stan. This is the only time you'll get this offer.",
				stanXcreatorImage: '/images/home/v6/stanXjustin.png',
			},
		},
	},
}

export default CONSTANTS
